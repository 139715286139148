// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "admin-lte"
// import "jquery";

// var jQuery = require('jquery')
// global.$ = global.jQuery = jQuery;
// window.$ = window.jQuery = jQuery;

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application.scss";

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
// });

// jQuery-ui
// import 'admin-lte/plugins/jquery-ui/jquery-ui.min.js';
// ChartJS
//import "admin-lte/plugins/chart.js/Chart.min";
// Sparkline
// import "admin-lte/plugins/sparklines/sparkline";
// JQVMap
// import "admin-lte/plugins/jqvmap/jquery.vmap.min";
// import "admin-lte/plugins/jqvmap/maps/jquery.vmap.usa";
// jQuery Knob Chart
// import "admin-lte/plugins/jquery-knob/jquery.knob.min";
// daterangepicker
// import "admin-lte/plugins/moment/moment.min";
// import "admin-lte/plugins/daterangepicker/daterangepicker";
// Tempusdominus Bootstrap 4
// import "admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min";
// Summernote
// import "admin-lte/plugins/summernote/summernote-bs4.min";
// overlayScrollbars
import "admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min";
// jQuery Mapael
// import "admin-lte/plugins/jquery-mousewheel/jquery.mousewheel.js";
// import "admin-lte/plugins/raphael/raphael.min";
// import "admin-lte/plugins/jquery-mapael/jquery.mapael.min";
// import "admin-lte/plugins/jquery-mapael/maps/usa_states.min";
// AdminLTE for demo purposes
// import "admin-lte/dist/js/demo";
// AdminLTE dashboard demo (This is only for demo purposes)
// import "admin-lte/dist/js/pages/dashboard";
// import "admin-lte/dist/js/pages/dashboard2";
// import "admin-lte/dist/js/pages/dashboard3";

import "packs/jquery.jpostal";